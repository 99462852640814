
































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';

import Footer from '@/components/Footer/Footer.vue';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import Header from '../../components/Header/Header.vue';
import TextModal from '../../components/TextModal/index.vue';

const FIRST_IMAGE = 1;

@Component({
  components: {
    Header,
    ContainerFluid,
    TextModal,
    Footer,
  },
})
export default class Launch extends Vue {
  private currentSlide = FIRST_IMAGE;
  private totalSlides = 3;
  private interval: ReturnType<typeof setInterval> | null = null;

  mounted() {
    this.setBackgroundImage();
  }

  beforeUnmount() {
    this.stopImageRotation();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get backgroundStyle() {
    return {
      backgroundImage: this.backgroundImage,
      backgroundPosition: this.isMobile ? '-300px center' : 'center center',
    };
  }

  get backgroundImage() {
    const images = require.context('@/assets/imgs/war', false, /\.(jpg|png)$/);

    const fileName = `slide-${this.isMobile ? FIRST_IMAGE : this.currentSlide}.${this.isMobile || this.currentSlide === FIRST_IMAGE ? 'jpg' : 'png'}`;

    return `url(${images(`./${fileName}`)})`;
  }

  startImageRotation() {
    this.interval = setInterval(() => {
      this.currentSlide = (this.currentSlide % this.totalSlides) + 1;
    }, 5000);
  }

  stopImageRotation() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  preloadImages() {
    const images = require.context('@/assets/imgs/war', false, /\.(jpg|png)$/);

    const slides = [1, 2, 3];

    slides.forEach((slide) => {
      const fileName = `slide-${slide}.${slide === 1 ? 'jpg' : 'png'}`;

      const img = new Image();

      img.src = images(`./${fileName}`);
    });
  }

  @Watch('isMobile')
  setBackgroundImage() {
    if (!this.isMobile) {
      this.startImageRotation();
    } else {
      this.currentSlide = FIRST_IMAGE;
    }

    this.preloadImages();
  }
}
