const Navbar = {
  state: {
    isShowNavbar: true,
    isShowItemNavbar: true,
    isShowButtonToggleMenu: false,
  },
  mutations: {
    setIsShowNavbar(state: Record<string, any>, payload: boolean) {
      state.isShowNavbar = payload;
    },
    setIsShowItemNavbar(state: Record<string, any>, payload: boolean) {
      state.isShowItemNavbar = payload;
    },
    setIsShowButtonToggleMenu(state: Record<string, any>, payload: boolean) {
      state.isShowButtonToggleMenu = payload;
    },
  },
  getters: {
    isShowNavbar: (state: Record<string, any>) => state.isShowNavbar,
    isShowItemNavbar: (state: Record<string, any>) => state.isShowItemNavbar,
    isShowButtonToggleMenu: (state: Record<string, any>) => state.isShowButtonToggleMenu,
  },
};

export default Navbar;
