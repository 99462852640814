
































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import YesIcon from '@/assets/icons/war/Yes.vue';
import NoIcon from '@/assets/icons/war/No.vue';

@Component({
  components: {
    YesIcon,
    NoIcon,
  },
})
export default class AcceptSwitch extends Vue {
  @Prop({ default: true }) accept!: boolean;

  private acceptLocal = true;

  @Watch('accept', {
    immediate: true,
  })
  handleSetAccept() {
    this.acceptLocal = this.accept;
  }

  handleEmitAccept(value: boolean) {
    if (this.accept === value) return;

    this.acceptLocal = value;

    this.$emit('onAccept', this.acceptLocal);
  }
}
