interface MenuInterface {
  amplitude: string;
  name: string;
  to: string;
  ref?: string;
  component: string;
  alt: string;
  disable?: boolean;
  planID?: boolean;
}

const MENU_GROUP = [
  {
    amplitude: 'Dashboard',
    name: 'Dashboard',
    to: '/dashboard',
    ref: 'menuApp',
    component: 'Dashboard',
    alt: 'Dashboard',
  },
  {
    amplitude: 'Shopping',
    name: 'Loja',
    to: '/loja',
    component: 'Shopping',
    alt: 'Loja',
  },
  {
    amplitude: 'Cadernos',
    name: 'Cadernos',
    to: '/cadernos',
    component: 'Cadernos',
    alt: 'Cadernos',
  },
  {
    amplitude: 'Cursos',
    name: 'Cursos',
    to: '/cursos',
    component: 'Cursos',
    alt: 'Cursos',
  },
  {
    amplitude: 'Conquistaê',
    name: 'Conquistaê',
    to: '/conquistae',
    component: 'Conquistae',
    alt: 'Conquistaê',
  },
  {
    amplitude: 'Planner',
    name: 'Planner',
    to: '/planner',
    component: 'Planner',
    alt: 'Planner',
  },
  {
    amplitude: 'Redação',
    name: 'Redação',
    to: '/redacao',
    component: 'Redacao',
    alt: 'Redação',
    planID: true,
  },
  {
    amplitude: 'Dúvidas',
    name: 'Dúvidas',
    to: '/duvidas',
    component: 'Duvidas',
    alt: 'Dúvidas',
    planID: true,
  },
  {
    amplitude: 'Exercícios',
    name: 'Banco de Questões',
    to: '/questoes',
    component: 'Exercicios',
    alt: 'Exercícios',
  },
  {
    amplitude: 'Simulados',
    name: 'Simulados',
    to: '/simulados',
    component: 'Simulados',
    alt: 'Simulados',
  },
  {
    amplitude: 'Vestibular',
    name: 'Vestibulares',
    to: '/vestibular',
    component: 'Vestibulares',
    alt: 'Vestibulares',
  },
];

const MENU_NAME = {
  DASHBOARD: 'Dashboard',
  SHOPPING: 'Loja',
  BOOK: 'Cadernos',
  COURSE: 'Cursos',
  PLANNER: 'Planner',
  REDACTION: 'Redação',
  DOUBTS: 'Dúvidas',
  EXERCISE: 'Banco de Questões',
  SIMULATED: 'Simulados',
  EXAM: 'Vestibulares',
  WAR: 'Conquistaê',
};

export {
  MenuInterface,
  MENU_GROUP,
  MENU_NAME,
};
