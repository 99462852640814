import {
  Vue, Component, Watch, Prop,
} from 'vue-property-decorator';
import ScrollControl from '@/share/Util/ScrollControl/ScrollControl';

const ROUTE_SIMULATED_RESULT = 'SimulatedResult';

@Component
export default class ConfigMapQuestions extends Vue {
  @Prop() tabMenu!: Array<Record<string, any>>;
  @Prop({ required: true }) questions!: Record<string, any>;
  @Prop() tabActive!: string;
  @Prop({ default: false }) isAllQuestions!: boolean;
  @Prop({ default: false }) hasRedaction!: boolean;

  private idTimeoutAnimation = 0;
  protected isAnimation = false;
  protected questionTab: Record<string, any> = {};
  protected indexQuestion: Record<string, any> = {
    lc_lt: 0,
    ch_ct: 0,
    cn_ct: 0,
    mt: 0,
  };
  protected currentNumbersQuestionTab: Record<string, any> = {
    lc_lt: 0,
    ch_ct: 0,
    cn_ct: 0,
    mt: 0,
  };

  protected setScroll = new ScrollControl();

  created() {
    this.getQuestionsTab();
  }

  beforeDestroy() {
    clearTimeout(this.idTimeoutAnimation);
  }

  get lastQuestionTab() {
    return this.questions[this.tabActive]?.length === this.indexQuestion[this.tabActive] + 1;
  }

  get lastQuestionTotal() {
    return this.lastQuestionTab && this.tabActive === this.tabMenu[this.tabMenu.length - 1].id;
  }

  get firstQuestionTab() {
    return this.indexQuestion[this.tabActive] === 0;
  }

  get firstQuestionTotal() {
    return this.firstQuestionTab && this.tabActive === this.tabMenu[0].id;
  }

  @Watch('tabActive')
  @Watch('indexQuestion', { deep: true })
  getQuestionsTab() {
    this.questionTab = this.questions[this.tabActive][this.indexQuestion[this.tabActive]];
  }

  @Watch('tabActive', { immediate: true })
  setCurrentNumbersQuestionTab() {
    if (!this.currentNumbersQuestionTab[this.tabActive]) {
      this.currentNumbersQuestionTab[this.tabActive] = this.currentNumberQuestion() + 1;
    }

    if (this.isAllQuestions) this.indexQuestion[this.tabActive] = 0;
  }

  currentNumberQuestion() {
    if (this.tabActive === 'lc_lt') return 0;

    if (this.tabActive === 'ch_ct') {
      const totalQuestionPrevious = Number(this.questions.lc_lt.length);
      return totalQuestionPrevious;
    }

    if (this.tabActive === 'cn_ct') {
      const totalQuestionPrevious = Number(this.questions.lc_lt.length) + Number(this.questions.ch_ct.length);
      return totalQuestionPrevious;
    }

    const totalQuestionPrevious = Number(this.questions.lc_lt.length) + Number(this.questions.ch_ct.length) + Number(this.questions.cn_ct.length);
    return totalQuestionPrevious;
  }

  setCurrentNumberQuestion(indexQuestion: number, currentNumberQuestion: number) {
    this.setAnimation();

    this.currentNumbersQuestionTab[this.tabActive] = currentNumberQuestion + 1;
    this.indexQuestion[this.tabActive] = indexQuestion;

    if (this.isAllQuestions) this.setScroll.scrollToElement(`container-${this.currentNumberQuestion() + (indexQuestion + 1)}`, { block: 'center' });
  }

  setAnimation() {
    if (this.isAllQuestions) return;

    this.isAnimation = true;

    this.idTimeoutAnimation = setTimeout(() => {
      this.isAnimation = false;
    }, 1200);
  }

  checkFirstQuestionTab() {
    if (this.firstQuestionTab && !this.firstQuestionTotal) {
      this.$emit('previousTab');

      if (!this.hasRedaction) {
        if (this.$route.name === ROUTE_SIMULATED_RESULT) this.previousQuestion();
        this.$nextTick(() => {
          this.indexQuestion[this.tabActive] = this.questions[this.tabActive]?.length - 1;
          this.currentNumbersQuestionTab[this.tabActive] = this.currentNumberQuestion() + this.questions[this.tabActive]?.length;
        });
      }
      return;
    }
    this.previousQuestion();
  }

  previousQuestion() {
    this.setAnimation();
    if (!this.firstQuestionTab) {
      this.currentNumbersQuestionTab[this.tabActive] -= 1;
      this.indexQuestion[this.tabActive] -= 1;
    }
  }

  checkLastQuestionTab() {
    if (this.lastQuestionTab && !this.lastQuestionTotal) {
      this.$emit('nextTab');
      if (!this.hasRedaction) {
        if (this.$route.name === ROUTE_SIMULATED_RESULT) this.nextQuestion();
        this.$nextTick(() => {
          this.indexQuestion[this.tabActive] = 0;
          this.currentNumbersQuestionTab[this.tabActive] = this.currentNumberQuestion() + 1;
        });
      }
      return;
    }
    this.nextQuestion();
  }

  nextQuestion() {
    this.setAnimation();
    if (!this.lastQuestionTab) {
      this.currentNumbersQuestionTab[this.tabActive] += 1;
      this.indexQuestion[this.tabActive] += 1;
    }
  }
}
