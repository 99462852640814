
























import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import EUAIcon from '@/assets/icons/war/EUA.vue';
import SpainIcon from '@/assets/icons/war/Spain.vue';

@Component({
  components: {
    EUAIcon,
    SpainIcon,
  },
})
export default class LanguageSwitch extends Vue {
  @Prop({ default: true }) language!: boolean;

  private languageLocal = true;

  @Watch('language', {
    immediate: true,
  })
  handleSetLanguage() {
    this.languageLocal = this.language;
  }

  handleEmitLanguage(value: boolean) {
    if (this.language === value) return;

    this.languageLocal = value;

    this.$emit('onLanguage', this.languageLocal);
  }
}
