import { render, staticRenderFns } from "./AcceptSwitch.vue?vue&type=template&id=431caed1&scoped=true&"
import script from "./AcceptSwitch.vue?vue&type=script&lang=ts&"
export * from "./AcceptSwitch.vue?vue&type=script&lang=ts&"
import style0 from "./AcceptSwitch.scss?vue&type=style&index=0&id=431caed1&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "431caed1",
  null
  
)

export default component.exports