




























































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import Eye from '@/assets/icons/Eye.vue';
import EyeSlash from '@/assets/icons/EyeSlash.vue';

const MAXIMUM_FONT_SIZE = 18;
const MINIMUM_FONT_SIZE = 10;

@Component({
  components: {
    Eye,
    EyeSlash,
  },
  mixins: [LightBoxImage],
})
export default class RadioAlternative extends Vue {
  @Prop({ required: true }) radioList!: Array<Record<string, any>>;
  @Prop({ required: true }) id!: number;
  @Prop({ required: true }) index!: number;
  @Prop({ default: 14 }) fontSizeProp!: number;

  private isHide = true;
  private data: Array<Record<string, any>> = [
    {
      title: '',
      value: false,
      isAble: true,
    },
  ];

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;

    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;

    return this.fontSizeProp;
  }

  @Watch('radioList', {
    immediate: true,
  })
  newData(): void {
    if (this.radioList && this.radioList.length) {
      this.data = this.radioList.map((item: object) => ({
        ...item,
        isAble: true,
      }));
    }
  }

  hideEye(index: number): void {
    const newData = this.data;
    newData[index].isAble = !newData[index]?.isAble;
    this.data = newData;
  }

  setValue(indexAlternative: number, idQuestion: number, indexQuestion: number, idOption: number) {
    this.$emit('selected-answer-in-question', indexAlternative, idQuestion, indexQuestion, idOption);
  }
}
