import { Component, Watch, Vue } from 'vue-property-decorator';

import { configureColors } from '@/share/Util/Color/ConfigureColors';
import { DARK_MODE_THEME, THEME } from '@/share/Util/Color/PlatformColors';

const ROUTE_WAR = 'conquistae';

@Component
export default class Theme extends Vue {
  mounted() {
    this.saveInitialThemeToStore();
  }

  get initialTheme() {
    return this.$store.getters.initialTheme;
  }

  get isWarRoute() {
    return this.$store.getters.isWarRoute;
  }

  get currentRoute() {
    return this.$route.path;
  }

  get isDarkMode() {
    return this.initialTheme === DARK_MODE_THEME;
  }

  @Watch('currentRoute')
  @Watch('isWarRoute')
  setTheme() {
    if (!this.isWarRoute) {
      this.restoreInitialTheme();
      return;
    }

    localStorage.setItem('ex-theme', DARK_MODE_THEME);

    this.$store.commit('setIsDarkMode', true);

    configureColors(THEME[DARK_MODE_THEME]);
  }

  @Watch('currentRoute', { immediate: true })
  saveToStoreIfRouteIsWar() {
    this.$store.commit('setIsWarRoute', this.currentRoute.includes(ROUTE_WAR));
  }

  restoreInitialTheme() {
    const localStorageThemeInitial = localStorage.getItem('ex-theme-initial') || '';

    const isDarkMode = localStorageThemeInitial === DARK_MODE_THEME;

    this.$store.commit('setInitialTheme', localStorageThemeInitial);
    this.$store.commit('setIsDarkMode', isDarkMode);

    localStorage.setItem('ex-theme', localStorageThemeInitial);

    configureColors(THEME[localStorageThemeInitial]);
  }

  saveInitialThemeToStore() {
    const localStorageTheme = localStorage.getItem('ex-theme-initial') || localStorage.getItem('ex-theme') || '';

    const isDarkMode = localStorageTheme === DARK_MODE_THEME;

    this.$store.commit('setInitialTheme', localStorageTheme);
    this.$store.commit('setIsDarkMode', isDarkMode);

    localStorage.setItem('ex-theme-initial', localStorageTheme);

    configureColors(THEME[localStorageTheme]);
  }
}
