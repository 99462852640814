
































import { Component, Vue, Prop } from 'vue-property-decorator';

import TargetIcon from '@/assets/icons/war/Target.vue';
import ArrowIcon from '@/assets/icons/Arrow.vue';

import { Menu as IMenu } from './interface';

@Component({
  components: {
    TargetIcon,
    ArrowIcon,
  },
})
export default class Menu extends Vue {
  @Prop() menu!: IMenu[];
  @Prop({ default: true }) isFirstStep!: boolean;
}
