



































import { Component, Vue } from 'vue-property-decorator';

import Modal from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    Modal,
  },
})
export default class ModalWarningRedaction extends Vue {
  handleClickButtonFinish() {
    this.$emit('click-button-finish');
  }

  handleClickButtonStartRedaction() {
    this.$emit('click-button-start-redaction');
  }
}
