var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ mobile: _vm.isMobile },attrs:{"id":"app"}},[(_vm.profile.institution)?_c('SplashScreen',{attrs:{"id":"splash-screen"}}):_vm._e(),_c('div',{class:{ isMovie: _vm.isMovie },on:{"click":function($event){return _vm.movieClick()}}}),(_vm.getIsShowNavbar)?_c('NavMenu',{attrs:{"isMobile":_vm.isMobile,"start-animate":""}}):_vm._e(),_c('div',{staticClass:"Sidebar",class:{
      mobile: _vm.isMobile,
      active: _vm.getSidebar,
      'decrease-sidebar': _vm.getDecreaseSidebar,
    }},[_c('div',{ref:"sidebarcontent",staticClass:"sidebar-content",class:{ 'close-content': _vm.getDecreaseSidebar },attrs:{"id":"sidebarContent"}})]),(_vm.appStart || _vm.isAnonymous)?_c('div',{staticClass:"app-container",class:{
      'menu-sidebar-space': !_vm.isMobile && _vm.isLogged,
      'show-item-menu': _vm.isShowItemNavBar,
      'sidebar-space': _vm.getSidebar,
      'mobile-sidebar': _vm.isMobile,
      'maximize-app': _vm.getDecreaseSidebar,
    }},[_c('div',{attrs:{"id":"top-0"}}),(_vm.showBannerPromotions)?_c('BannerPromotions',{on:{"close":_vm.handleCloseBannerPromotion}}):_vm._e(),_c('div',{staticClass:"container-fluid mt-3"},[(_vm.showModalRenewPlan)?_c('BannerRenewPlan',{attrs:{"payment":_vm.payment},on:{"close":_vm.handleCloseButton}}):_vm._e()],1),_c('transition',{attrs:{"name":_vm.transitionName}},[_c('router-view')],1),_c('FloatingButton')],1):_vm._e(),(_vm.isLogged)?_c('ModalEndOfPlanTrial'):_vm._e(),(_vm.isLogged)?_c('ModalPlanUpdate'):_vm._e(),(_vm.isLogged)?_c('ModalWelcomePlanTrial'):_vm._e(),(_vm.isLogged)?_c('ModalExitSimulated'):_vm._e(),(_vm.isLogged)?_c('ModalReportProblem'):_vm._e(),(_vm.isLogged)?_c('ModalCourse'):_vm._e(),(_vm.isLogged)?_c('ModalNotContentPlan'):_vm._e(),(_vm.isLogged)?_c('ModalTrialPlan'):_vm._e(),(_vm.isLogged)?_c('ModalWelcome',{on:{"start":_vm.handleStartBanner}}):_vm._e(),(_vm.isLogged)?_c('ModalWelcomeFeature'):_vm._e(),(_vm.isLogged)?_c('ModalEndFeature'):_vm._e(),(_vm.isLogged)?_c('ModalPopUpPlanFree'):_vm._e(),(_vm.isLogged)?_c('ModalContentFree'):_vm._e(),(_vm.isLogged)?_c('ModalPopUpPlanFreeSimulated'):_vm._e(),(_vm.isLogged)?_c('ModalPopUpPlanFreeExam'):_vm._e(),(_vm.isLogged)?_c('ModalCredits'):_vm._e(),_c('ModalRankingPass'),_c('ModalConsumerCredits'),_c('ExToastify')],1)}
var staticRenderFns = []

export { render, staticRenderFns }