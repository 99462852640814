import { ConfigWar } from '@/globalInterfaces/War';

const War = {
  state: {
    configWar: null as ConfigWar | null,
    isWarRoute: false,
  },
  mutations: {
    setConfigWar(state: Record<string, any>, payload: ConfigWar) {
      state.configWar = payload;
    },
    setIsWarRoute(state: any, payload: boolean) {
      state.isWarRoute = payload;
    },
  },
  getters: {
    configWar: (state: Record<string, any>) => state.configWar,
    isWarRoute: (state: Record<string, any>) => state.isWarRoute,
  },
};

export default War;
