import { RouteConfig } from 'vue-router';

const NAME_ROUTES_WAR = {
  LAUNCH: 'Launch',
  WELCOME: 'Welcome',
};

export const War: Array<RouteConfig> = [
  {
    path: '/conquistae',
    name: NAME_ROUTES_WAR.LAUNCH,
    component: () => import(/* webpackChunkName: "war" */ '../../pages/War/pages/Launch/Launch.vue'),
    meta: { title: 'conquistae' },
  },
  {
    path: '/war',
    name: NAME_ROUTES_WAR.WELCOME,
    component: () => import(/* webpackChunkName: "war" */ '../../pages/War/War.vue'),
  },
];
