





































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import Arrow from '@/assets/icons/Arrow.vue';
import {
  STATUS_SKIP,
  STATUS_ANSWERED,
  CLASS_SKIP,
  CLASS_ANSWERED,
  CLASS_INCORRECT,
  CLASS_CURRENT,
  CLASS_ACTIVE,
  NUMBER_OPTIONS_CORRECT,
} from './constants/statusQuestions';

import { Question, Option } from './interface/IMapQuestions';

const TEXT_BTN_SHOW_MORE = 'Ver mais';
const TEXT_BTN_CLOSE = 'Ver menos';

@Component({
  components: {
    Arrow,
  },
  filters: {
    setNumber(value: number) {
      return (value < 10) || (value > 90 && value < 100) ? `0${value}` : value;
    },
  },
})
export default class MapQuestions extends Vue {
  @Prop({ required: true }) questions!: Array<Question>;
  @Prop({ required: true }) currentIndex!: number;
  @Prop({ default: true }) isResult!: boolean;
  @Prop({ default: false }) hasBtnShowMore!: boolean;
  @Prop({ default: 'Mapa de questões' }) title!: string;
  @Prop() currentNumberQuestion!: number;

  private textButton = TEXT_BTN_SHOW_MORE;

  get hasButtonShowMore() {
    return this.hasBtnShowMore && this.isMobile;
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get isMinimizeMap() {
    return this.textButton === TEXT_BTN_SHOW_MORE;
  }

  setCurrentNumberQuestion(indexQuestion: number) {
    if (this.currentNumberQuestion) return indexQuestion + this.currentNumberQuestion + 1;

    return indexQuestion + 1;
  }

  emitCurrentNumberQuestion(indexQuestion: number, currentNumberQuestion?: number) {
    this.$emit('set-question', indexQuestion, currentNumberQuestion);
  }

  changeStatusMapQuestion() {
    this.textButton = this.isMinimizeMap ? TEXT_BTN_CLOSE : TEXT_BTN_SHOW_MORE;
  }

  @Watch('currentIndex', {
    immediate: true,
  })
  setClassNavLink() {
    if (this.questions && this.questions.length > 0) {
      this.questions.forEach((item, questionIndex) => {
        if (this.currentIndex >= questionIndex && !this.questions[questionIndex]?.response) {
          this.questions[questionIndex].response = STATUS_SKIP;
        }
      });
    }
  }

  setQuestion(index: number) {
    this.emitCurrentNumberQuestion(index, index + this.currentNumberQuestion);
  }

  itemResponse(item: Question, navIndex: number) {
    if (!item) return '';

    let className = '';

    if (item.response === STATUS_ANSWERED) className = CLASS_ANSWERED;

    if (item.response === STATUS_SKIP) className = CLASS_SKIP;

    else if (item.options && this.isResult) {
      item.options.forEach((option: Option) => {
        if (option.correct === NUMBER_OPTIONS_CORRECT && !option.value) {
          className = CLASS_INCORRECT;
        }
      });
    }

    const classCurrentIndex = this.isResult ? CLASS_ACTIVE : CLASS_CURRENT;

    const active = this.currentIndex === navIndex ? classCurrentIndex : '';

    return `${className} ${active}`;
  }
}
