










































import { Component, Vue, Watch } from 'vue-property-decorator';

import Header from './components/Header/Header.vue';
import Menu from './components/Menu/Menu.vue';
import Background from './components/Background/Background.vue';
import Configuration from './components/Configuration/Configuration.vue';
import Welcome from './components/Welcome/Welcome.vue';
import Leveling from './components/Leveling/Leveling.vue';
import Footer from '@/components/Footer/Footer.vue';

import WarLoading from './WarLoading.vue';

import WarService from '@/services/War/WarService';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';

import { ConfigWar } from '@/globalInterfaces/War';

import { STEPS_WAR } from '@/constant/War';

import { menu } from './constants';

@Component({
  components: {
    Header,
    Menu,
    Background,
    WarLoading,
    Configuration,
    Welcome,
    Leveling,
    Footer,
  },
})
export default class War extends Vue {
  private isLoading = true;
  private MENU = menu;
  private STEPS_WAR = STEPS_WAR;

  private warService = new WarService();

  created() {
    this.showButtonToggleMenuAndHiddenItem();

    this.getConfig();
  }

  beforeDestroy() {
    this.hiddenButtonToggleMenuAndShowItem();
  }

  get isMobile() {
    return this.$responsive.isMobile;
  }

  get listMenu() {
    const newMenu = this.MENU;

    if (!this.configWar) {
      newMenu[0].active = true;
      newMenu[1].active = false;
    } else {
      newMenu[0].active = false;
      newMenu[1].active = true;
    }

    return newMenu;
  }

  get configWar(): ConfigWar | null {
    return this.$store.getters.configWar;
  }

  @Watch('configWar', {
    immediate: true,
    deep: true,
  })
  redirectWar() {
    if (!this.configWar || this.configWar?.step !== STEPS_WAR.WAR) return;

    console.log('redirecionar para a página principal');
    // redirecionar para a página principal
    // this.$router.push({

    // });
  }

  showButtonToggleMenuAndHiddenItem() {
    this.$store.commit('setIsShowButtonToggleMenu', true);
    this.$store.commit('setIsShowItemNavbar', false);
  }

  hiddenButtonToggleMenuAndShowItem() {
    this.$store.commit('setIsShowButtonToggleMenu', false);
    this.$store.commit('setIsShowItemNavbar', true);
  }

  async getConfig() {
    try {
      this.isLoading = true;

      const config = await this.warService.getConfig();

      this.$store.commit('setConfigWar', config);
    } catch (error) {
      if (error?.response?.status === STATUS_RESPONSE_API.CLIENT_ERROR_NOT_FOUND) return;

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar as configurações.',
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }
}
