





































import { Component, Prop, Vue } from 'vue-property-decorator';

const DEFAULT_FILL = '#E7E7E8';

@Component
export default class Menu extends Vue {
  @Prop({ default: DEFAULT_FILL }) fill!: string;
  @Prop({ default: '15' }) width!: string;
  @Prop({ default: '15' }) height!: string;
}
