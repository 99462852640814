import AppClient from '../AppClient';

import { CreateConfig } from './WarInterface';

import { List } from '@/globalInterfaces/List';

export default class VideoService {
  private basePathSimulation = process.env.VUE_APP_SIMULATION_API;

  private ClientSimulation = new AppClient({ url: this.basePathSimulation });

  async getConfig() {
    const URI = '/war/config';

    const response = await this.ClientSimulation.get(URI);

    return response?.data;
  }

  async createConfig(data: CreateConfig) {
    const URI = '/war/config';

    const response = await this.ClientSimulation.post(URI, data);

    return response?.data;
  }

  async updateStep(step: number) {
    const URI = '/war/config';

    const response = await this.ClientSimulation.put(URI, {
      step,
    });

    return response?.data;
  }

  async getListLeveling(): Promise<List> {
    const URI = '/lists/war';

    const response = await this.ClientSimulation.get(URI);

    return response?.data;
  }
}
