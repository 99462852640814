









































































import { Component, Mixins } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';
import Select from '@/components/Input/Select/Select.vue';

import InputDate from '../InputDate/InputDate.vue';
import AcceptSwitch from '../AcceptSwitch/AcceptSwitch.vue';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch.vue';
import BoxMessage from '../BoxMessage/BoxMessage.vue';
import Soldier from '../Soldier/Soldier.vue';

import DigitMessage from '@/mixins/DigitMessage';

import WarService from '@/services/War/WarService';

import { MESSAGE } from './constants';

@Component({
  components: {
    InputDate,
    AcceptSwitch,
    Select,
    LanguageSwitch,
    Loading,
    BoxMessage,
    Soldier,
  },
})
export default class Configuration extends Mixins(DigitMessage) {
  private date = '';
  private accept = true;
  private language = true;
  private isLoading = false;

  private warService = new WarService();

  mounted() {
    this.digitMessage(MESSAGE);
  }

  handleSetAccept(value: boolean) {
    this.accept = value;
  }

  handleSetLanguage(value: boolean) {
    this.language = value;
  }

  handleSetDate(value: string) {
    this.date = value;
  }

  handleNextStep() {
    try {
      this.isLoading = true;

      this.warService.createConfig({
        isEnem: this.accept,
        isEnglish: this.language,
        ...(this.date && { end_date: this.date }),
      });
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar as configurações.',
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }
}
