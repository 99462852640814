export const menu = [
  {
    title: '1ª Missão |  Definir Objetivos',
    active: true,
  },
  {
    title: '2ª Missão |  Avaliação de Admissão',
    active: false,
  },
];
