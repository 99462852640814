













import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class BoxMessage extends Vue {
  @Prop() maxWidth!: number;
}
