
































import { Component, Vue, Prop } from 'vue-property-decorator';

import ContainerFluid from '@/components/ContainerFluid/ContainerFluid.vue';

import iBug from '@/assets/icons/Bug.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

const DEFAULT_COLOR = 'var(--primarycolor-dark)';

@Component({
  components: {
    ContainerFluid,
    iBug,
  },
})
export default class Footer extends Vue {
  @Prop() colorText!: string;
  @Prop({ default: DEFAULT_COLOR }) colorButton!: string;
  @Prop() colorLine!: string;
  @Prop() colorIcon!: string;

  private setModal = new ActiveModal();

  handleClickReportProblem() {
    this.setModal.activeModal('modalReportProblem');
    this.setTrackAmplitude();
  }

  setTrackAmplitude() {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Footer',
          local: 'Reportar problema',
        },
      },
    });
  }
}
