


























import { Component, Vue } from 'vue-property-decorator';

import Loading from '@/components/Loading/Loading.vue';

import BoxMessage from '../BoxMessage/BoxMessage.vue';
import Soldier from '../Soldier/Soldier.vue';

import WarService from '@/services/War/WarService';

import { ConfigWar } from '@/globalInterfaces/War';

import { STEPS_WAR } from '@/constant/War';

@Component({
  components: {
    Loading,
    BoxMessage,
    Soldier,
  },
})
export default class Welcome extends Vue {
  private isLoading = false;

  private warService = new WarService();

  get configWar(): ConfigWar | null {
    return this.$store.getters.configWar;
  }

  async handleStartList() {
    try {
      this.isLoading = true;

      await this.warService.updateStep(STEPS_WAR.QUESTIONS);

      this.updateConfigStore();
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar as configurações.',
        status: 'error',
      });
    } finally {
      this.isLoading = false;
    }
  }

  updateConfigStore() {
    this.$store.commit('setConfigWar', {
      ...this.configWar,
      step: STEPS_WAR.QUESTIONS,
    });
  }
}
