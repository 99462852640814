


























































































































































import {
  Component,
  Prop,
  Watch,
  Mixins,
} from 'vue-property-decorator';

import BreadCrumbs from '@/components/BreadCrumbs/BreadCrumbs.vue';
import ToggleSwitch from '@/components/ToggleSwitch/index.vue';

import Credits from './components/Credits/Credits.vue';

import WarningPaymentPendency from '@/pages/Shopping/components/WarningPaymentPendency/WarningPaymentPendency.vue';

import LogoExplicae from '@/assets/imgs/logo/LogoExplicae.vue';
import LogoExplicaeFree from '@/assets/imgs/logo/LogoExplicaeFree.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import { setImageProfile } from '@/share/Util/Image/Image';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import NotificationsServices from '@/services/Notifications/NotificationsServices';
import PaymentService from '@/services/Payment/PaymentService';

import { logout } from '@/share/Util/Logout/index';

import { PAYMENT_STATUS } from '@/pages/Shopping/constants/index';

import CreditsConsumer from '@/mixins/CreditsConsumer';

import {
  INSTITUTION_NAME_DEFAULT,
  TAB_PROFILE_MY_PERFORMANCE,
  TAB_NAME_PROFILE_NOTIFICATION,
  LOGO_EXPLICAE_FREE,
  LOGO_EXPLICAE,
  THREE_SECONDS_IN_MILLISECONDS,
  TAB_INDEX_NOTIFICATION,
  LINK_SITE_EXPLICAE,
  ROUTE_STORE,
  ROUTE_RENEW_PENDENCIES,
  ROUTE_PENDENCIES,
} from './constants';

import { ROUTER_PAGE_RENEW_PLAN, ROUTER_PAGE_NAME_SHOPPING, PENDING_ROUTES } from '@/constant/Payment';

@Component({
  components: {
    BreadCrumbs,
    ToggleSwitch,
    LogoExplicae,
    LogoExplicaeFree,
    WarningPaymentPendency,
    Credits,
  },
})
export default class AppHeader extends Mixins(CreditsConsumer) {
  @Prop() private profile!: string;
  @Prop() private isMobile!: boolean;
  @Prop() private user!: boolean;

  private isActive = false;
  private isLoadingPendency = false;
  private isPendency = false;

  private timer: any;
  private quantityNotifications = 0;

  private currentTime = '00:00';
  private avatar = '';

  private NotificationsServices = new NotificationsServices();
  private PaymentService = new PaymentService();

  created() {
    this.getPendency();
  }

  mounted() {
    setInterval(this.timeFunction);
    this.getQuantityNotifications();
  }

  get showModalPendencyPlan() {
    return !PENDING_ROUTES.includes(this.currentRoute) && !this.isLoadingPendency && this.isPendency;
  }

  get showDefaultBreadCrumb() {
    return !this.user && !this.isStoreOrPaymentRoute;
  }

  get isStoreOrPaymentRoute() {
    return this.currentRoute === ROUTE_STORE;
  }

  get isB2b() {
    return !!this.$store.getters.profile.institution?.name;
  }

  get isLogged() {
    return this.$store.getters.profile?.idstudent;
  }

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  get logoExplicae() {
    return this.isPlanFree ? LOGO_EXPLICAE_FREE : LOGO_EXPLICAE;
  }

  get isWarRoute() {
    return this.$store.getters.isWarRoute;
  }

  get plan() {
    return this.$store.getters.plan;
  }

  get userName() {
    const name = this.$store.getters.profile.nickname;
    const splitName = name.split(' ');

    if (splitName.length >= 2) {
      return `${splitName[0]} ${splitName[1]}`;
    }

    return name;
  }

  get institution() {
    return this.$store.getters.profile.institution;
  }

  get institutionName() {
    return this.institution?.name || INSTITUTION_NAME_DEFAULT;
  }

  get logoB2b() {
    return this.institution?.image;
  }

  get isAnonymousUser() {
    const hasProfile = this.$store.getters.profile.idstudent;

    return !hasProfile;
  }

  get currentRoute() {
    return this.$route.name || '';
  }

  userLogout() {
    logout({});
  }

  async getPendency() {
    try {
      this.isLoadingPendency = true;

      const [response] = await this.PaymentService.getPayment({
        statusExternal: PAYMENT_STATUS.PENDING,
      });

      if (response) {
        this.isPendency = true;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingPendency = false;
    }
  }

  async getQuantityNotifications() {
    try {
      if (!this.isLogged) return;

      const notifications = await this.NotificationsServices.getNotificationsUnread();

      if (notifications.notifications.length) {
        this.$store.commit('setNotifications', notifications.notifications);
      }

      if (notifications && notifications.count) {
        this.quantityNotifications = notifications.count;

        this.$store.commit('setQuantityNotifications', notifications.count);

        this.showToastNotification();
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Watch('$store.getters.avatar', {
    immediate: true,
  })
  setAvatar() {
    this.avatar = setImageProfile(this.$store.getters.avatar);
  }

  @Watch('avatar')
  setStyleAvatar() {
    return {
      backgroundImage: ` url("${this.avatar}")`,
      backgroundColor: 'rgba(0,0,0,0.2)',
    };
  }

  showSubMenu() {
    clearInterval(this.timer);
    this.isActive = !this.isActive;
  }

  hideSubMenu(item: any) {
    item.stopPropagation();
    this.timer = setTimeout(() => {
      this.isActive = false;
      clearInterval(this.timer);
    }, 200);
  }

  setProfile() {
    this.$store.commit('setTabProfile', TAB_PROFILE_MY_PERFORMANCE);
  }

  setNotification() {
    this.setTrackAmplitude('Botão - Notificações');

    const { profileMenu } = this.$store.getters;

    const indexProfileMenuNotification = profileMenu.findIndex((option: string) => option === TAB_NAME_PROFILE_NOTIFICATION);

    this.$store.commit('setTabProfile', indexProfileMenuNotification);
  }

  timeFunction() {
    const time = new Date();
    this.currentTime = `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}`;
  }

  setTrackAmplitude(local: string) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'AppHeader',
          local,
        },
      },
    });
  }

  showToastNotification() {
    const { isShowNotification } = this.$store.getters;

    if (!isShowNotification) return;

    this.$store.dispatch('Toast/setToast', {
      text: `Você tem uma nova notificação.
      Clique aqui para visualizar`,
      status: 'warning',
      duration: THREE_SECONDS_IN_MILLISECONDS,
      onClick: this.redirectProfileNotifications,
    });

    this.$store.commit('setIsShowNotification', false);
  }

  redirectProfileNotifications() {
    this.$store.commit('setTabProfile', TAB_INDEX_NOTIFICATION);

    this.$router.push({
      name: 'Profile',
    });
  }

  handleRedirectLogo() {
    if (!this.isAnonymousUser) {
      this.$router.push({
        name: 'Dashboard',
      });

      return;
    }

    window.open(LINK_SITE_EXPLICAE, '_self');
  }

  handleClickPendency() {
    const pendingRoute = !ROUTER_PAGE_RENEW_PLAN.includes(this.currentRoute)
    && !ROUTER_PAGE_NAME_SHOPPING.includes(this.currentRoute) ? ROUTE_RENEW_PENDENCIES : ROUTE_PENDENCIES;

    this.$router.push({
      name: pendingRoute,
    });
  }
}
