






























import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import FeedbackRequiredField from '@/components/FeedbackRequiredField/index.vue';

import Calendar from '@/assets/icons/Calendar.vue';

@Component({
  components: {
    Calendar,
    FeedbackRequiredField,
  },
})
export default class InputDate extends Vue {
  @Prop() date!: string;
  @Prop() label!: string;

  private dateLocal = '';

  @Watch('date')
  setDateLocal() {
    this.dateLocal = this.date;
  }

  @Watch('dateLocal')
  handleUpdateDate() {
    this.$emit('onDate', this.dateLocal);
  }
}
