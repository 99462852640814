import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DigitMessage extends Vue {
  private indexMessage = 0;

  digitMessage(message: string) {
    const container = this.$refs.message as HTMLElement;

    if (this.indexMessage < message.length) {
      container.textContent += message.charAt(this.indexMessage);

      this.indexMessage += 1;

      setTimeout(() => this.digitMessage(message), 50);
    }
  }
}
