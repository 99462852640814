







import { Component, Vue, Prop } from 'vue-property-decorator';

enum Name {
  'soldier',
  'soldier-radio',
  'soldier-salutes',
  'soldier-tablet',
}

@Component({})
export default class Soldier extends Vue {
  @Prop({ default: 'soldier' }) name!: Name;
}
