
















import {
  Component,
  Vue,
} from 'vue-property-decorator';

import { configureColors } from '@/share/Util/Color/ConfigureColors';
import { THEME, LIGHT_MODE_THEME, DARK_MODE_THEME } from '@/share/Util/Color/PlatformColors';

@Component
export default class ToggleSwitch extends Vue {

  get isWarRoute() {
    return this.$store.getters.isWarRoute;
  }

  setTheme() {
    if (this.isWarRoute) return;

    const isDarkMode = this.activeDarkMode();

    const currentTheme = isDarkMode ? LIGHT_MODE_THEME : DARK_MODE_THEME;

    localStorage.setItem('ex-theme', currentTheme);
    localStorage.setItem('ex-theme-initial', currentTheme);

    configureColors(THEME[currentTheme]);

    this.$store.commit('setIsDarkMode', this.activeDarkMode());
  }

  activeDarkMode() {
    if (this.isWarRoute) return true;

    const currentTheme = localStorage.getItem('ex-theme');

    const isDarkMode = currentTheme === DARK_MODE_THEME;

    return isDarkMode;
  }
}
