const Theme = {
  state: {
    isDarkMode: false,
    initialTheme: '',
  },
  mutations: {
    setIsDarkMode(state: Record<string, any>, payload: boolean) {
      state.isDarkMode = payload;
    },
    setInitialTheme(state: Record<string, any>, payload: string) {
      state.initialTheme = payload;
    },
  },
  getters: {
    isDarkMode: (state: Record<string, any>) => state.isDarkMode,
    initialTheme: (state: Record<string, any>) => state.initialTheme,
  },

};

export default Theme;
